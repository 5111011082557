import React from 'react';
import MultiSelectDropdown from '../Fafi/FafiPage';

const EmptyPage: React.FC = () => {
    const options = [
        { id: 1, label: 'Option1' },
        { id: 2, label: 'Option2' },
        { id: 3, label: 'Option3' },
        { id: 4, label: 'Option4' },
        { id: 5, label: 'Option5' },
        { id: 6, label: 'Option6' },
      ];
  return (
    <div>

<MultiSelectDropdown options={options} />
    </div>
  );
};

export default EmptyPage;