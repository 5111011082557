import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QualicolisLogo from '../../assets/qualicolis-logo.jpg';
import './LoginPage.css';
import { Login } from '../../models/Login';
import { LoginResponse } from '../../models/LoginResponse';
import axios from 'axios';

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormError('');
    if (!username || !password) {
      setFormError('Both username and password are required');
      return;
    } else {
      loginEndpoint(username, password);
    }
    // console.log('Logging in with:', username, password);
    // navigate('/ForgotPassword');
  };

  useEffect(() => {
    
    const fetchData = () => {
      let email = getCookie('email');
      let token = getCookie('token');
      if (email && token) {
        navigate('/messages');
      }
    }
  
    fetchData();
  }, [navigate]);

  function getCookie(name: string) {
    let cookieName = name + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(cookieName) === 0) {
            return c.substring(cookieName.length, c.length);
        }
    }

    return null;
}
  const headers = {
    "Content-Type": "application/json"
  };

  function loginEndpoint(username: string, password: string){
    let login: Login = {
      email: username,
      password:password,
      role:'admin'
  };

    axios.post<LoginResponse>(`${process.env.REACT_APP_BACKEND_URL}/Accounts/login`, login, {
      headers: headers,
   })
   .then(function (response) {
      console.log('Response data:', response.data);
      redirectAndSave(response, username)
  })
  }

  function redirectAndSave(data: any, username: string){
    if (data.data.tok) {
      setCookie('token', data.data.tok);
      setCookie('email', username);
      navigate('/messages');
    }
  }

  function setCookie(name: string, value: string) {
    var cookie = name + "=" + encodeURIComponent(value);
        document.cookie = cookie;
  }

  return (
    <div className='container'>
      <div className='header'>
        <div className="photo-QualicolisLogo">
          <img src={QualicolisLogo} alt=" QualicolisLogo" />
        </div>
      </div>
      <div className='form'>
        <div className='login-text'>Sign in</div>
        <form onSubmit={handleSubmit}>
          {formError && <div className='error-message' style={{ color: 'red' }}>{formError}</div>}
          <div className='username-text'>Username</div>
          <div className='username'>
            <label htmlFor="username"></label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className='password-text'>Password</div>
          <div className='password'>
            <label htmlFor="password"></label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className='login-button' type="submit">Login</button>
          {formError && <div className='error-message'>{formError}</div>}
        </form> 
      </div>
    </div>
  );
};

export default LoginPage;
