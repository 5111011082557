import React, { useEffect, useState } from "react";
import "./Confirmation.css";
import LogoImage from "../../assets/qualicolis-logo.jpg";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Account } from "../../models/Account";
import { GetAccountRequest } from "../../models/GetAccountRequest";

const headers = {
  "Content-Type": "application/json",
};

const ConfirmationPage = () => {
  const [searchParams] = useSearchParams();
  const [accountState, setaccountState] = useState<Account>({
    dateOfBirth: "",
    email: "",
    gender: "",
    name: "",
    role: "",
    type: "",
  });

  const navigate = useNavigate();
  const id = searchParams.get("id");

  const handleAccept = () => {
    if (id) {
      try {
        let request: GetAccountRequest = {
          id,
        };

        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/Accounts/sendPasswordEmail`,
            request,
            {
              headers: headers,
            }
          )
          .then(function (response) {
            setaccountState(response.data);
            navigate("/success");
          });
      } catch (error) {
        console.error("Error creating account:", error);
      }
    }
  };

  const handleDecline = () => {
    if (id) {
      try {
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/Accounts/delete`,
            { id },
            {
              headers: headers,
            }
          )
          .then(function (response) {
            setaccountState(response.data);
            navigate("/error");
          });
      } catch (error) {
        console.error("Error creating account:", error);
      }
    }
  };

  useEffect(() => {
    const getAccount = () => {
      if (id) {
        try {
          const request: GetAccountRequest = {
            id,
          };
          axios
            .post<Account>(
              `${process.env.REACT_APP_BACKEND_URL}/Accounts/get`,
              request,
              {
                headers: headers,
              }
            )
            .then(function (response) {
              setaccountState(response.data);
            });
        } catch (error) {
          console.error("Error creating account:", error);
        }
      }
    };

    getAccount();
  }, [id]);

  return (
    <div>
      <header className="thank-you-headerc">
        <img src={LogoImage} alt="Logo" className="header-logoc" />
      </header>
      <div className="thank-you-containerc">
        <div className="thank-you-contentc">
          <h2>Account Details</h2>

          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>{accountState.name}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{accountState.email}</td>
              </tr>
              <tr>
                <td>Gender:</td>
                <td>{accountState.gender}</td>
              </tr>
              <tr>
                <td>Date of Birth:</td>
                <td>{accountState.dateOfBirth}</td>
              </tr>
              <tr>
                <td>Role:</td>
                <td>{accountState.role}</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>{accountState.type}</td>
              </tr>
            </tbody>
          </table>
          <button className="accept-buttonn" onClick={handleAccept}>
            Accept
          </button>
          <button className="decline-buttonn" onClick={handleDecline}>
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
