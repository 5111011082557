import React from 'react';
import './ThankYouPage.css';
import LogoImage from '../../assets/qualicolis-logo.jpg'; 

const ThankYouPage = () => {
  return (
    <div>
      <header className="thank-you-header">
        <img src={LogoImage} alt="Logo" className="header-logo" />
      </header>
      <div className='thank-you-container'>
        <div className='thank-you-content'>
          <h2>C'est bon!</h2>
          <p>Votre livreur est autorisé </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;