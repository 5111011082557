import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CreateNotificationRequest } from '../../models/CreateNotificationRequest';
import { NotificationM } from '../../models/NotificationM';
import './MessagesSender.css';

interface Message {
  id: number;
  text: string;
}

const headers = {
  "Content-Type": "application/json"
};

const Messages: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputText, setInputText] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showLeftMenu, setShowLeftMenu] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    
    const fetchData = () => {
      let email = getCookie('email');
      let token = getCookie('token');
      if (!email || !token) {
        navigate('/login');
      } else {

        axios.post<NotificationM[]>(`${process.env.REACT_APP_BACKEND_URL}/Notifications/getall`, {
          headers: headers,
       })
       .then(function (response) {
          console.log('Response data:', response.data);
          let messages : Message[] = [];
          response.data.forEach(notif => {
            let message : Message = {
              id : 0,
              text: notif.content
            };
            messages.push(message);
            setMessages(messages);
          });
      })
      }
    }
  
    fetchData();
  }, [navigate]);

  function getCookie(name: string) {
    let cookieName = name + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(cookieName) === 0) {
            return c.substring(cookieName.length, c.length);
        }
    }

    return null;
}

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleSendMessage = () => {
    setShowConfirmation(true);
  };

  const handleConfirmSend = () => {
    if (inputText.trim() !== '') {
      const newMessage: Message = {
        id: messages.length + 1,
        text: inputText,
      };
      sendNotification(inputText);
      setMessages([...messages, newMessage]);
      setInputText('');
      setShowConfirmation(false);
    }
  };

  const handleCancelSend = () => {
    setShowConfirmation(false);
  };

  const toggleLeftMenu = () => {
    setShowLeftMenu(!showLeftMenu);
  };

  async function sendNotification(text: string){

    let request: CreateNotificationRequest = {
      text,
      title:'Notification QualiColis',
  };

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/Notifications/send`, request, {
      headers: headers,
   })
   .then(function (response) {
      // If the request is successful, you can access the response data here
      console.log('Response data:', response.data);
  })
  }

const getAllAccounts = async () =>{
  const token = getCookie('token');
  const headers = {token}
  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Accounts/csv`,{headers})
  const csv = response.data;
      
  // Create a Blob from the CSV
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
  // Create a link element
  const link = document.createElement('a');
  
  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', 'data.csv');
  
  // Append the link to the body
  document.body.appendChild(link);
  
  // Trigger the download
  link.click();
  
  // Clean up and remove the link
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

  return (
    <div className="page-container">
      <div className={`left-menu ${showLeftMenu ? 'visible' : 'hidden'}`}>
        <MenuIcon onClick={toggleLeftMenu} />
        <Link to="/messages">Messages</Link>
        <Link to="/suppliers">Suppliers</Link>
        <button className='export-button-csv' onClick={getAllAccounts}>Export</button>
      </div>
        <div className='display-messages'>
          {messages.map((message) => (
            <div key={message.id} className='message-box'>
              {message.text}
            </div>
          ))}
        </div>
        <div className='messages-text-box'>
          <input
            type="text"
            value={inputText}
            onChange={handleInputChange}
            placeholder="Type a message..."/>
        <button className='send-button' onClick={handleSendMessage}>Send</button>
        </div>
        {showConfirmation && (
          <div className='confirmation-popup'>
            <div className='confirmation-text'>Are you sure you want to send this message?</div>
            <button className='accept-button' onClick={handleConfirmSend}>Accept</button>
            <button className='decline-button' onClick={handleCancelSend}>Decline</button>
          </div>
        )}   
      </div>
  );
};

export default Messages;
