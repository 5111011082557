import React, { useEffect, useRef, useState } from 'react';

type Option = {
  id: number;
  label: string;
};

type MultiSelectDropdownProps = {
  options: Option[];
};

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({ options }) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionChange = (option: Option) => {
    console.log(option)
    if (selectedOptions.some(selectedOption => selectedOption.id === option.id)) {
      setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption.id !== option.id));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleDeselectOption = (optionId: number) => {
    setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption.id !== optionId));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const isSelected = (option: Option) => {
    return selectedOptions.some(selectedOption => selectedOption.id === option.id);
  };

  const baseWidth = 400;
  const additionalWidthPerOption = 20;

  const buttonHeight = '30px';
  let dropdownWidth = baseWidth;
  if (selectedOptions.length > 3) {
    dropdownWidth += (selectedOptions.length - 3) * additionalWidthPerOption;
  }
  
  const selectedOptionStyle = {
    border: '1px solid pink',
    borderRadius: '4px',
    padding: '2px 6px',
    marginRight: '4px',
    backgroundColor: 'pink',
    display: 'flex',
    alignItems: 'center',
    marginTop:'3px',
    marginBottom:'3px'
  };

  const deselectButtonStyle = {
    marginRight: '5px',
    cursor: 'pointer',
  };

  return (
<div ref={dropdownRef} style={{ position: 'relative', width: `${dropdownWidth}px` }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <button 
          type="button"
          style={{
            flexGrow: 1,
            minHeight: buttonHeight,
            backgroundColor: 'white',
            padding: '5px',
            borderTop: '1px solid grey',
            borderBottom: '1px solid grey',
            borderLeft: '1px solid grey',
            borderRight: 'none',
            borderRadius: '1px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          {selectedOptions.length === 0 ? (
            <span>Select items</span>
          ) : (
            selectedOptions.map(option => (
              <span key={option.id} style={selectedOptionStyle}>
              <span 
                style={deselectButtonStyle}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeselectOption(option.id);
                }}
              >
                X
              </span>
              {option.label}
            </span>
            ))
          )}
        </button>
        <div 
          onClick={() => setShowDropdown(!showDropdown)}
          style={{
            width: '20px',
            minHeight: buttonHeight,
            backgroundColor: 'white',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1px solid grey',
            borderRight: '1px solid grey',
            borderBottom: '1px solid grey',
            borderLeft: 'none',
          }}
        >
        </div>
      </div>
      {showDropdown && (
        <div style={{ 
          position: 'absolute', 
          top: '100%', 
          left: 0, 
          zIndex: 2, 
          backgroundColor: 'white', 
          border: '1px solid grey', 
          width: '100%', 
          minWidth: `${dropdownWidth}px`
        }}>          {options.map(option => (
            <div key={option.id}>
              <label>
                <input
                  type="checkbox"
                  checked={isSelected(option)}
                  onChange={() => handleOptionChange(option)}
                />
                {option.label}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;