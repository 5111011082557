import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ConfirmationPage from './pages/ConfirmationPage/Confirmation';
import EmptyPage from './pages/EmptyPage/EmptyPage';
import ForgotPassword from './pages/ForgetPasswordPage/ForgotPassword';
import Login from './pages/LoginPage/LoginPage';
import MessagesSender from './pages/MessagesPage/MessagesSender';
import SuppliersPage from './pages/SuppliersPage/SuppliersPage';
import ThankYouManagerPage from './pages/ThankYouManagerPage/ThankYouManagerPage';
import ThankYouPage from './pages/ThankYouPage/ThankYouPage';

const App: React.FC = () => {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/messages' element={<MessagesSender />} />
        <Route path='/ForgotPassword' element={<ForgotPassword />} />
        <Route path='/suppliers' element={<SuppliersPage />} />
        <Route path='/success' element={<ThankYouPage />} />
        <Route path='/error' element={<ThankYouManagerPage />} />
        <Route path='/fffffffff' element={<EmptyPage />} />
        <Route path='/confirmation' element={<ConfirmationPage />} />
      </Routes>
    </Router>
  );
};

export default App;