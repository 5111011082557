import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import QualicolisLogo from '../../assets/qualicolis-logo.jpg';
import './ForgotPassword.css';
import { Login } from '../../models/Login';
import axios from 'axios';

const ForgotPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id')
  const headers = {
    "Content-Type": "application/json"
  };

  const navigate = useNavigate();
  const handlePasswordReset = () => {
    if (password === confirmPassword && password.length >= 8) {
      loginEndpoint(password);
    } else {
      setErrorMessage("Passwords don't match or are less than 8 characters.");
    }
  };

  function loginEndpoint(password: string){
    const displayId = id ?? 'No ID provided';
    let login: Login = {
      email: displayId,
      password:password,
      role:'user'
  };

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/Accounts/password`, login, {
      headers: headers,
   })
   .then(function (response) {
      // If the request is successful, you can access the response data here
      console.log('Response data:', response.data);
      navigate('/thankyou');
  })
  }

  return (
    <div className='forgot-password-container'>
      <div className='forgot-password-header'>
          <img src={QualicolisLogo} alt="QualicolisLogo" />
       </div> 
        <div className='forgot-password-form'>
          <div className='forgot-password-text'>Mot de passe oublié</div>
          <div className='new-password-text'>Nouveau mot de passe</div>
          <div className='new-password'><input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /></div>
          <div className='new-confirmed-password-text'>Confirmez le mot de passe</div>
          <div className='new-confirmed-password'> <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} /></div>
          <button className='reset-password-button' onClick={handlePasswordReset}>Réinitialiser le mot de passe</button>
          {errorMessage && <div className='change-password-error-message'>{errorMessage}</div>} </div>
      </div>
  );
};

export default ForgotPassword;
