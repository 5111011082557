import React from 'react';
import './ThankYouManagerPage.css';
import LogoImage from '../../assets/qualicolis-logo.jpg'; 

const ThankYouManagerPage = () => {
  return (
    <div>
      <header className="thank-you-header">
        <img src={LogoImage} alt="Logo" className="header-logo" />
      </header>
      <div className='thank-you-container'>
        <div className='thank-you-content'>
        <h2>Refusé!</h2>
        <p>Le livreur a été refusé! </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYouManagerPage;